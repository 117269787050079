import React from 'react';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import BreederProfileFollowButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileFollowButtonContainer';

import CertifiedBreederBadge from './CertifiedBreederBadge';
import useBreederSearchUpdatesExp from '../hooks/useBreederSearchUpdatesExp';

// header for exp_breeder_search_updates
function ExperimentHeader() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  if (isFetchingBreeder) {
    return (
      <div className="BreederProfileHeaderSkeleton container-xxl bg-sand flex flex-column items-center pt6 pb4">
        <SkeletonBlock className="br-100 mb4" height="64px" width="64px" />
        <SkeletonBlock className="font-24 mb1" height="1.2em" width="70%" />
        <SkeletonBlock className="font-14" height="1.4em" width="60%" />
      </div>
    );
  }

  return (
    <div className="BreederProfileHeader container-xxl bg-sand tc pt6 pb4">
      <div className="dib relative mb4">
        <UserAvatar
          size="lg"
          user={{
            id: breeder.user.id,
            initials: breeder.initials,
            profile_photo_url: breeder.avatar_profile_photo_url
          }}
        />
        <CertifiedBreederBadge />
      </div>
      <h1 className="font-24 lh-title">{breeder.name}</h1>
      <p className="font-14 lh-body secondary">
        Raised by {breeder.display_name}
        {breeder.city_state ? ` in ${breeder.city_state}` : ''}
      </p>
    </div>
  );
}

export default function BreederProfileHeader() {
  const { breederDisplayName } = useSelector(({ server }) => server);

  const { isExperiment } = useBreederSearchUpdatesExp();

  if (isExperiment) {
    return <ExperimentHeader />;
  }

  return (
    // moved this out of show.html.erb file to setup experiment
    // but can always move this back afterwards depending on experiment result
    <div className="container-xxl pt3 pt8-md">
      <div className="flex flex-wrap items-center gap-16 pv3 pv8-md">
        <h1 className="BreederProfileShowPage__displayName font-28 md:font-40 lh-title">
          {breederDisplayName}
        </h1>
        <div className="hidden md:block" style={{ minWidth: '6em' }}>
          <BreederProfileFollowButtonContainer />
        </div>
      </div>
    </div>
  );
}
