import { Splide, SplideSlide } from '@splidejs/react-splide';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { isEmpty } from 'validate.js';

import Icon from 'dpl/common/components/Icon';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederProfileLitterPuppyCard, {
  PuppyPropTypes
} from '../BreederProfileLitterPuppyCard';

const CARDS_PER_PAGE = isLessThanBreakpoint('lg') ? 2 : 3;

export default function BreederProfileLitterCarousel({
  breederProfileId,
  litterName,
  puppies,
  description
}) {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const isNextButtonDisabled = activeIndex >= puppies.length - CARDS_PER_PAGE;
  const isPrevButtonDisabled = activeIndex <= 0;

  function handleActiveSlide(slide) {
    setActiveIndex(slide.index);
  }

  if (isEmpty(puppies)) {
    return null;
  }

  return (
    <div className="BreederProfileLitterCarousel container-xxl">
      <div className="grid gap-8 mb5 mb6-md">
        <h3 className="font-20 md:font-24 lh-title">{litterName}’s litter</h3>
        {description && (
          <p className="font-16 md:font-18 secondary">{description}</p>
        )}
      </div>
      <Splide
        ref={splideRef}
        className="br3 overflow-hidden"
        onActive={handleActiveSlide}
        options={{
          arrows: false,
          drag: puppies.length > 3,
          dragMinThreshold: 20,
          flickMaxPages: CARDS_PER_PAGE,
          flickPower: 300,
          gap: 16,
          keyboard: 'focused',
          pagination: false,
          perPage: CARDS_PER_PAGE,
          snap: true,
          start: activeIndex
        }}
      >
        {puppies.map((puppy, idx) => (
          <SplideSlide key={puppy.id}>
            <BreederProfileLitterPuppyCard
              puppy={puppy}
              breederProfileId={breederProfileId}
              analytics={{ position: idx + 1 }}
            />
          </SplideSlide>
        ))}
      </Splide>
      {puppies.length > CARDS_PER_PAGE && (
        <div className="mt3 mt7-md secondary">
          <button
            type="button"
            className={classnames('bg-white ba b--default br-100 mr4', {
              'o-60': isPrevButtonDisabled
            })}
            disabled={isPrevButtonDisabled}
            onClick={() => splideRef.current.go('<')}
          >
            <div className="flex items-center justify-center ph3 pv3">
              <Icon name="fetch-arrow-left" />
            </div>
          </button>
          <button
            type="button"
            className={classnames('bg-white ba b--default br-100', {
              'o-60': isNextButtonDisabled
            })}
            disabled={isNextButtonDisabled}
            onClick={() => splideRef.current.go('>')}
          >
            <div className="flex items-center justify-center ph3 pv3">
              <Icon name="fetch-arrow-right" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

BreederProfileLitterCarousel.propTypes = {
  breederProfileId: PropTypes.string.isRequired,
  litterName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  puppies: PropTypes.arrayOf(PuppyPropTypes).isRequired
};
