import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Badge from 'dpl/common/design_system/Badge';
import ImagePlaceholder from 'dpl/components/MediaGrid/ImagePlaceholder';
import SmartImage from 'dpl/components/SmartImage';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { openPuppyPage } from 'dpl/puppy_page/utils/helpers';
import { capitalize } from 'dpl/shared/utils';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import FavoriteButton from 'dpl/favorites/components/FavoriteButton';
import { FOLLOWABLE_TYPES } from 'dpl/favorites/utils/constants';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';

const IMAGE_SIZE = isLessThanBreakpoint('lg') ? '335px' : '416px';

export default function BreederProfileLitterPuppyCard({
  className,
  puppy,
  breederProfileId,
  analytics
}) {
  const { viewFeatureLocation } = useBreederProfileTabs();
  const { isLoggedIn, isBuyer } = useCurrentUser();
  const { breederDisplayName } = useSelector(({ server }) => server);

  const unavailable = puppy.reserved || puppy.snoozed;

  const ageGenderDescription = [
    puppy.age_description,
    capitalize(puppy.gender || '')
  ]
    .filter(Boolean)
    .join(' • ');

  const sharedAnalytics = {
    ...analytics,
    view: ANALYTICS_VIEWS.BREEDER_PROFILE,
    view_feature: ANALYTICS_VIEW_FEATURES.PUPPY_CARD,
    view_feature_location: viewFeatureLocation,
    dog_id: puppy.id,
    breeder_profile_id: breederProfileId,
    breed_id: puppy.breed_id
  };

  const handleCardClick = useCallback(
    ({ currentTarget, target }) => {
      if (currentTarget !== target && !currentTarget.contains(target)) {
        return;
      }

      sendRudderstackEvent(
        ANALYTICS_EVENTS.CARD_CLICKED_BY_USER,
        sharedAnalytics
      );

      openPuppyPage({ puppyId: puppy.id });
    },
    [puppy, sharedAnalytics]
  );

  return (
    <ClickableDiv
      className={className}
      data-test-id={`puppy-${puppy.id}`}
      onClick={handleCardClick}
    >
      <div className="BreederProfileLitterPuppyCard h-100 flex flex-column br3 ba b--light-gray overflow-hidden relative">
        {puppy.profile_photo_url ? (
          <SmartImage
            src={puppy.profile_photo_url}
            Tag="div"
            className="w-100 bg-center cover br3 br--top"
            loadingClass="bg-light-gray"
            height={IMAGE_SIZE}
            allowContextMenu
            crop
          />
        ) : (
          <div style={{ height: IMAGE_SIZE }}>
            <ImagePlaceholder className="bg-branch-light" />
          </div>
        )}
        <div className="white absolute w-100 top-0 right-0">
          <div className="ph4 pv4 absolute right-0">
            {(!isLoggedIn || isBuyer) && (
              <FavoriteButton
                analytics={{
                  ...sharedAnalytics,
                  breeder_name: breederDisplayName,
                  breeder_profile_id: Number(breederProfileId)
                }}
                followableTypeId={puppy.id}
                followableType={FOLLOWABLE_TYPES.DOG}
                checkedIconProps={{ name: 'fetch-heart-full-puppy' }}
                uncheckedIconProps={{
                  name: 'fetch-heart-dark',
                  className: 'default-color o-100'
                }}
              />
            )}
          </div>
        </div>
        <div className="pv4 ph4 bg-white">
          <div className="flex items-center justify-between">
            <p className="font-serif font-20 truncate">{puppy.name}</p>
            <Badge size="small" color={unavailable ? 'rubber' : 'gray'}>
              {unavailable ? 'Reserved' : puppy.ready_for_adoption_date_v2}
            </Badge>
          </div>

          {ageGenderDescription && (
            <p className="font-16 secondary mv1">{ageGenderDescription}</p>
          )}
        </div>
      </div>
    </ClickableDiv>
  );
}

export const PuppyPropTypes = PropTypes.shape({
  reserved: PropTypes.bool.isRequired,
  breed_id: PropTypes.number.isRequired,
  gender: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  profile_photo_url: PropTypes.string,
  snoozed: PropTypes.bool.isRequired,
  age_description: PropTypes.string,
  ready_for_adoption_date_v2: PropTypes.string
});

BreederProfileLitterPuppyCard.propTypes = {
  className: PropTypes.string,
  breederProfileId: PropTypes.string.isRequired,
  puppy: PuppyPropTypes.isRequired,
  analytics: PropTypes.shape({
    position: PropTypes.number
  })
};

BreederProfileLitterPuppyCard.defaultProps = {
  className: null,
  analytics: {}
};
