import React, { useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';

import BreederProfileCurrentLittersSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileCurrentLittersSectionSkeleton';
import { LITTER_DISPLAY_STATES } from 'dpl/shared/constants/shared';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import Button from 'dpl/common/design_system/Button';
import BreederProfileLitterPuppyCard from 'dpl/components/BreederProfile/Show/BreederProfileLitterPuppyCard';

const COLLAPSED_PUPPIES_COUNT = 5;

export default function PuppiesList() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const {
    isFetching,
    value: { data: litters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      status: LITTER_DISPLAY_STATES.CURRENT,
      exclude_adult: true
    })
  );

  const sortedPuppies = useMemo(() => {
    return litters
      .flatMap(litter => nameUnnamedPuppies(litter.puppies))
      .sort((a, b) => new Date(b.birth_date) - new Date(a.birth_date));
  }, [litters]);

  const isCollapsible = sortedPuppies.length > COLLAPSED_PUPPIES_COUNT;
  const [isCollapsed, toggleIsCollapsed] = useReducer(
    collapsed => !collapsed,
    () => !isCollapsible
  );

  if (isFetching) {
    return <BreederProfileCurrentLittersSectionSkeleton />;
  }

  if (sortedPuppies.length === 0) {
    return null;
  }

  const listings = isCollapsed
    ? sortedPuppies.slice(0, COLLAPSED_PUPPIES_COUNT)
    : sortedPuppies;

  return (
    <div className="PuppiesList container-xxl flex flex-column pb6">
      <h3 className="mb6 font-24 lh-title">Available puppies</h3>
      <ul className="grid gap-24">
        {listings.map((puppy, idx) => (
          <li key={puppy.id}>
            <BreederProfileLitterPuppyCard
              puppy={puppy}
              breederProfileId={breederProfileId}
              analytics={{ position: idx + 1 }}
            />
          </li>
        ))}
      </ul>

      {isCollapsible && (
        <Button
          className="font-16 stone-700 w-100 mt4"
          variant="outline"
          size="default"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? 'See more puppies' : 'See less puppies'}
        </Button>
      )}
    </div>
  );
}
