import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederProfilePuppiesResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import SmartImage from 'dpl/components/SmartImage';
import { openPuppyPage } from 'dpl/puppy_page/utils/helpers';
import { capitalize } from 'dpl/shared/utils';
import Badge from 'dpl/common/design_system/Badge';
import ImagePlaceholder from 'dpl/components/MediaGrid/ImagePlaceholder';
import BreederProfileCurrentPuppiesSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileCurrentPuppiesSkeleton';
import { BreederProfilePuppiesPropTypes } from 'dpl/shared/utils/customPropTypes';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';
import {
  NoAvailablePuppies,
  SearchPuppiesNearYouCard
} from 'dpl/components/BreederProfile/Show/SearchPuppiesPaths';
import { FOLLOWABLE_TYPES } from 'dpl/favorites/utils/constants';
import FavoriteButton from 'dpl/favorites/components/FavoriteButton';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';

export const SECTION_ID = 'puppies-section';
const SMART_IMAGE_PROPS = {
  Tag: 'div',
  className: 'w-100 bg-center cover br3 br--top',
  crop: true,
  loadingClass: 'bg-light-gray',
  height: '210px',
  allowContextMenu: true,
  lazy: true
};

function PuppyCard({ analytics, puppy, breederProfileId, programName }) {
  const { isLoggedIn, isBuyer } = useCurrentUser();

  const unavailable = puppy.reserved || puppy.snoozed;
  const ageGenderDescription = [
    puppy.age_description,
    capitalize(puppy.gender || '')
  ]
    .filter(Boolean)
    .join(' • ');

  const sharedAnalytics = {
    ...analytics,
    view: ANALYTICS_VIEWS.BREEDER_PROFILE,
    view_feature: ANALYTICS_VIEW_FEATURES.PUPPY_CARD,
    view_feature_location:
      ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_PUPPIES_CAROUSEL,
    dog_id: puppy.id,
    breeder_profile_id: breederProfileId,
    breed_id: puppy.breed_id
  };

  const handleCardClick = useCallback(
    ({ currentTarget, target }) => {
      if (currentTarget !== target && !currentTarget.contains(target)) {
        return;
      }

      sendRudderstackEvent(
        ANALYTICS_EVENTS.CARD_CLICKED_BY_USER,
        sharedAnalytics
      );

      openPuppyPage({ puppyId: puppy.id });
    },
    [puppy, sharedAnalytics]
  );

  return (
    <ClickableDiv data-test-id={`puppy-${puppy.id}`} onClick={handleCardClick}>
      <div className="BreederProfilePuppyCard h-100 flex flex-column br3 ba b--gray-200 overflow-hidden relative">
        {puppy.profile_photo_url ? (
          <SmartImage src={puppy.profile_photo_url} {...SMART_IMAGE_PROPS} />
        ) : (
          <div style={{ height: SMART_IMAGE_PROPS.height }}>
            <ImagePlaceholder className="bg-branch-light" />
          </div>
        )}
        <div className="white absolute w-100 top-0 right-0">
          <div className="ph4 pv4 absolute right-0">
            {(!isLoggedIn || isBuyer) && (
              <FavoriteButton
                analytics={{
                  ...sharedAnalytics,
                  breeder_name: programName,
                  breeder_profile_id: Number(breederProfileId)
                }}
                followableTypeId={puppy.id}
                followableType={FOLLOWABLE_TYPES.DOG}
                checkedIconProps={{ name: 'fetch-heart-full-puppy' }}
                uncheckedIconProps={{
                  name: 'fetch-heart-dark',
                  className: 'default-color o-100'
                }}
              />
            )}
          </div>
        </div>
        <div className="pv4 ph4 bg-white">
          <div className="flex items-center justify-between">
            <p className="font-serif font-20 truncate">{puppy.name}</p>
            <Badge size="small" color={unavailable ? 'rubber' : 'gray'}>
              {unavailable ? 'Reserved' : puppy.ready_for_adoption_date_v2}
            </Badge>
          </div>

          {ageGenderDescription && (
            <p className="font-16 secondary mv1">{ageGenderDescription}</p>
          )}
          <p className="font-16 secondary">{puppy.breed_name}</p>
        </div>
      </div>
    </ClickableDiv>
  );
}

PuppyCard.propTypes = {
  puppy: PropTypes.shape(BreederProfilePuppiesPropTypes).isRequired,
  breederProfileId: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  analytics: PropTypes.shape({
    position: PropTypes.number
  })
};

PuppyCard.defaultProps = {
  analytics: {}
};

export default function BreederProfileCurrentPuppiesSectionContainer() {
  const {
    breeds,
    breederProfileId,
    breederDisplayName,
    analyticsPageName,
    isSearchEngineReferral
  } = useSelector(({ server }) => server);

  const {
    isFetching,
    value: { data: puppiesData }
  } = useResourceQuery(
    breederProfilePuppiesResourceDefinition(breederProfileId)
  );

  const validPuppies = nameUnnamedPuppies(puppiesData);
  const hasNoPuppies = validPuppies.length === 0;

  if (isFetching) {
    return <BreederProfileCurrentPuppiesSkeleton />;
  }

  if (hasNoPuppies && !isSearchEngineReferral) {
    return null;
  }

  const containsAdults = validPuppies.find(pup => pup.adult);
  const title = containsAdults ? 'Puppies and older dogs' : 'Puppies';

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-litters-and-puppies"
    >
      <section
        id={SECTION_ID}
        className="BreederProfileCurrentPuppiesSection bg-subtle-light-gray pv12 pv20-md"
      >
        <div className="container-xxl">
          {hasNoPuppies && isSearchEngineReferral ? (
            <NoAvailablePuppies breeds={breeds} />
          ) : (
            <>
              <h3 className="mb6 mb10-md font-24 md:font-40 lh-title">
                {title}
              </h3>

              <HorizontalSlider
                className="BreederProfilePuppiesSlider"
                containerClassName="BreederProfilePuppiesSlider__container flex flex-nowrap w-100 gap-8 md:gap-16 lg:gap-16"
                iconClassName="f3 bg-white o-80 br-100 box-shadow-strong b black pv2 ph2"
                iconName="fetch-chevron"
              >
                {validPuppies.map((puppy, idx) => (
                  <div key={puppy.id} className="flex ws-normal">
                    <PuppyCard
                      puppy={puppy}
                      breederProfileId={breederProfileId}
                      programName={breederDisplayName}
                      analytics={{ position: idx + 1 }}
                    />
                  </div>
                ))}
                {isSearchEngineReferral && (
                  <div key="search-puppies-card" className="flex ws-normal">
                    <SearchPuppiesNearYouCard breeds={breeds} />
                  </div>
                )}
              </HorizontalSlider>
            </>
          )}
        </div>
      </section>
    </SectionViewTracker>
  );
}
